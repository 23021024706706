'use client';
import { EmailIcon, PhoneIcon } from '@chakra-ui/icons';
import { Box, Flex, Heading, Text } from '@chakra-ui/react';
import { getImageProps } from 'next/image';
import Link from 'next/link';

import { getImageLink } from '@/services/link.service';
import { ImageTransformationKeys } from '@/types/files/files.types';
import { directusImageLoader } from '@/utils/loaders';

import Styles from './MeetTeamCard.module.scss';

type Team = {
  email: string;
  full_name: string;
  image_id: string;
  role: string;
  phone_number: string;
  image: string;
  imageKeys: ImageTransformationKeys;
  defaultImage: string;
};

const MeetTeamCard = ({
  full_name,
  email,
  image,
  role,
  phone_number,
  imageKeys,
  defaultImage,
}: Team) => {
  const common = {
    alt: 'team-member',
    width: 416,
    height: 416,
    loader: directusImageLoader,
  };
  const imagesrc = getImageLink(image || defaultImage);
  const {
    props: { srcSet: mobile },
  } = getImageProps({
    ...common,
    src: `${imagesrc}?key=${imageKeys.mobileKey}`,
  });

  const {
    props: { srcSet: desktop, ...rest },
  } = getImageProps({
    ...common,
    src: `${imagesrc}?key=${imageKeys.desktopKey}`,
  });

  return (
    <>
      <Box className={Styles.meetTeamCard} backgroundColor={'white'}>
        <Box
          className={Styles.meetTeamImgBlock}
          width={{ base: '100%', md: 'auto' }}
        >
          <picture className={Styles.meetTeamImage}>
            <source media="(max-width: 575px)" srcSet={mobile} />
            <source media="(min-width: 576px)" srcSet={desktop} />
            <img {...rest} alt="team-member" />
          </picture>
        </Box>
        <Box
          className={Styles.headingElement}
          paddingX={{ base: 6 }}
          paddingY={{ base: 10 }}
        >
          <Heading as="h4" className={Styles.titleMd} marginBottom={2}>
            {full_name}
          </Heading>
          <Text
            as="b"
            className={Styles.titleText}
            display={'block'}
            marginBottom={5}
          >
            {role}
          </Text>
          <Flex dir="row" alignItems="center" gap={3} marginBottom={3}>
            <EmailIcon
              className="emailIcon"
              width={'15px'}
              height={'15px'}
              marginRight={1}
            />
            <Link className="titleSm" href={`mailto:${email}`}>
              {email}
            </Link>
          </Flex>
          <Flex dir="row" alignItems="center" gap={3}>
            <PhoneIcon
              className="phoneIcon"
              width={'13px'}
              height={'13px'}
              marginRight={2}
            />
            <Link className="titleSm" href={`tel:${phone_number}`}>
              {phone_number}
            </Link>
          </Flex>
        </Box>
      </Box>
    </>
  );
};

export default MeetTeamCard;
